import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./UserSlice";
import tablesReducer from "./TablesSlice";

const appReducer = combineReducers({
  user: userReducer,
  tables: tablesReducer,
});

type appState = ReturnType<typeof appReducer>;

export const resetStore = () => ({type: 'reset'});

const rootReducer = (state: appState | undefined, action: AnyAction) => {
  if (action.type === 'reset') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
