import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ZipTableIcon } from "../Assets/Icons";
import { Panel } from "../Assets/Panel";
import { Sp2 } from "../Components/Spacer";
import { TextField } from "../Components/TextField";

type SignUpControlProps = {
    onSignUp?: (email: string, password: string, userName: string) => Promise<string>;
};

export const SignUpControl = (props: SignUpControlProps) => {
    const { onSignUp } = props;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userName, setUserName] = useState("");
    const [signUpError, setSignUpError] = useState("");

    const clickSignUp = async (): Promise<void> => {
        if (onSignUp) {
            const errorMessage = await onSignUp(email, password, userName);
            setSignUpError(errorMessage);
        }
    };

    return (<div className="mt-2 mx-auto" style={{ maxWidth: 400 }}>
        <div className="text-center p-1"><ZipTableIcon width="2em" height="2em" /></div>
        <h5 className="text-center p-1">Sign Up</h5>
        <div className="text-center p-1">Create your new account.</div>
        <Alert
            show={!!signUpError}
            variant="danger"
            dismissible
            onClose={() => {
                setSignUpError("");
            }}>
            <FontAwesomeIcon icon={faCircleExclamation} /><Sp2 />{signUpError}
        </Alert>
        <Panel style={{ marginBottom: '20px', padding: '20px' }}>
            <div>
                <TextField id="email"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div>
                <TextField id="password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div>
                <TextField id="username"
                    label="Username"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                />
            </div>
            <Button onClick={clickSignUp}>Sign Up</Button>
        </Panel>
        <Panel>
            <div>Already have an account? <Link to="/auth/signin">Sign In</Link></div>
        </Panel>
    </div>);
};