import { useState } from "react";
import ToolBar from "../Components/ToolBar";
import { useNavigate } from "react-router-dom";
import { apiCreateTable } from "../Api/Tables";
import { apiUploadFile } from "../Api/Upload";
import { clearTables } from "../State/TablesSlice";
import { apiDetectFileSchema, TableSchema } from "../Api/Schema";
import { SchemaConfig } from "../Components/SchemaConfig";
import { useAppDispatch, useAppSelector } from "../State/Hooks";
import FileDragUploadPanel from "../Components/FileDragUploadPanel";
import FileProgress from "../Components/FileProgress";
import * as humanize from "humanize-plus";
import { Stepper, Step, StepLabel } from "@mui/material";
import { TextField } from "../Components/TextField";
import { Button } from "react-bootstrap";

enum Steps {
  Upload = 0,
  Schema,
}

const NewTablePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const idToken = useAppSelector((state) => state.user.idToken);
  const [progress, setProgress] = useState(0.0);
  const [activeStep, setActiveStep] = useState(Steps.Upload);
  const [tableName, setTableName] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadContentType, setUploadContentType] = useState("");
  const [uploadFileSize, setUploadFileSize] = useState(0);
  const [schema, setSchema] = useState({
    columns: [],
    sampleData: [],
  } as TableSchema);
  const [analysisComplete, setAnalysisComplete] = useState(false);

  const clickCreateTable = async () => {
    if (!idToken) {
      throw new Error('idToken must be provided.');
    }
    await apiCreateTable(idToken, {
      displayName: tableName,
      importSettings: {
        uploadFile: uploadFileName,
        contentType: uploadContentType,
      }
    });
    dispatch(clearTables());
    navigate("/");
  };

  const tableNameFromFileName = (fileName: string) =>
    fileName.split(".").slice(0, -1).join(".");

  const canCreateTable = () => !!uploadFileName && !!tableName;
  const clickCancel = () => navigate(-1);

  const onBeginUpload = async (file: File) => {
    setActiveStep(activeStep + 1);
    setFileName(file.name);
    setUploadFileSize(file.size);
    setUploadContentType(file.type);
    setTableName(tableNameFromFileName(file.name));

    // Run the upload and the schema analysis in parallel
    await Promise.all([
      execDetect(idToken, "text/csv", file),
      execUpload(idToken, file, setProgress),
    ]);

    setProgress(100);
  };

  const execDetect = async (
    idToken: string | null,
    contentType: string,
    file: File
  ): Promise<void> => {
    const { schema } = await apiDetectFileSchema(idToken, contentType, file);
    setSchema(schema);
    setAnalysisComplete(true);
  };

  const execUpload = async (
    idToken: string | null,
    file: File | null | undefined,
    setProgress: (s: number) => void
  ): Promise<void> => {
    const t0 = performance.now();
    const resp = await apiUploadFile(idToken, file, setProgress);
    const t1 = performance.now();

    setUploadFileName(resp.storedFileName);
    console.log(
      `Uploaded ${resp.sourceFileName} to ${resp.storedFileName} (${resp.fileSize} bytes) in ${Math.round(t1 - t0)} ms`
    );
  };

  return (
    <>
      <ToolBar />
      <div className="mx-auto" style={{ maxWidth: '900px' }}>
        <h4 className="mt-4">Create a new table</h4>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ padding: 2 }}>
          <Step>
            <StepLabel>Upload Data</StepLabel>
          </Step>
          <Step>
            <StepLabel>Set Up Your Table</StepLabel>
          </Step>
        </Stepper>
        {activeStep === Steps.Upload
          ? <FileDragUploadPanel onBeginUpload={onBeginUpload} />
          : <div className="d-flex flex-column" style={{ gap: '1rem' }}>
            <div className="d-flex flex-row gap-2" >
              <div style={{ flex: '1 1 0px' }}>
                <FileProgress
                  caption={progress !== 100 ? "Uploading Data" : "Data Upload Complete"}
                  detail={`${fileName} [${humanize.fileSize(uploadFileSize)}]`}
                  progress={progress} />
              </div>
              <div style={{ flex: '1 1 0px' }}>
                <FileProgress
                  caption={!analysisComplete ? "Analyzing Data" : "Analysis Complete"}
                  detail={!analysisComplete
                    ? "Detecting fields and formats"
                    : `Detected fields and formats from the first ${schema.sampleData.length} records`}
                  indeterminate
                  progress={analysisComplete ? 100 : 0} />
              </div>
            </div>
            <TextField
              id="tableName"
              label="Choose a name for your new table"
              placeholder="my-table-name"
              value={tableName}
              onChange={(evt) => setTableName(evt.target.value)}
            />
            <div style={{ fontSize: '0.875rem' }} >
              Choose your column names and data types using some sample data we gathered for you.
              Don't worry, all of these settings can also be changed after your table is created.
            </div>
            <SchemaConfig
              schema={schema}
              onUpdateSchema={setSchema}
            />
            <div className="mt-3 mb-4 d-flex flex-row" style={{ gap: '1rem' }}>
              <Button
                onClick={clickCreateTable}
                disabled={!canCreateTable()}
              >
                Create Table
              </Button>
              <Button variant="outline-primary" onClick={clickCancel}>
                Cancel
              </Button>
            </div>
          </div>
        }
      </div>
    </>
  );

};

export default NewTablePage;
