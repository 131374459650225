import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ZipTableIcon } from "../Assets/Icons";
import { Panel } from "../Assets/Panel";
import { Sp2 } from "../Components/Spacer";
import { TextField } from "../Components/TextField";

type SignInControlProps = {
    onSignIn?: (userName: string, password: string) => Promise<boolean>;
};

export const SignInControl = (props: SignInControlProps) => {
    const { onSignIn } = props;
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showLoginError, setShowLoginError] = useState(false);

    const clickSignIn = async (): Promise<void> => {
        if (onSignIn) {
            const success = await onSignIn(userName, password);
            setShowLoginError(!success);
        }
    };

    const onEnterKeyDownSignIn = async (evt: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        if (evt.code === "Enter" || evt.code === "NumpadEnter") {
            evt.preventDefault();
            await clickSignIn();
        }
    };

    return (<div className="mt-2 mx-auto" style={{ maxWidth: 400 }}>
        <div className="text-center p-1"><ZipTableIcon width="2em" height="2em" /></div>
        <h5 className="text-center p-1">Sign In</h5>
        <Alert
            show={showLoginError}
            variant="danger"
            dismissible
            onClose={() => {
                setShowLoginError(false);
            }}>
            <FontAwesomeIcon icon={faCircleExclamation} /><Sp2 />Incorrect username or password.
        </Alert>
        <Panel style={{ marginBottom: '20px', padding: '20px' }}>
            <div>
                <TextField
                    id="username"
                    label="Username or Email"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    onKeyDown={onEnterKeyDownSignIn}
                />
            </div>
            <div>
                <TextField
                    id="password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={onEnterKeyDownSignIn}
                />
            </div>
            <Button onClick={clickSignIn}>Sign In</Button>
        </Panel>
        <Panel>
            <div>Don't have an account? <Link to="/auth/signup">Sign Up</Link></div>
        </Panel>
    </div>);
};