import { throwOnError } from "./Errors";

type AuthSuccessResponse = {
  token: string;
  session: string;
  user: UserData;
};

export type UserData = {
  id: string;
  displayName: string;
  userName: string;
  email: string;
  picture: string;
};

export type SignInRequest = {
  userName: string;
  password: string;
};

export type SignInResponse = AuthSuccessResponse;

export const apiSignIn = async (
  req: SignInRequest
): Promise<SignInResponse> => {
  let resp = await fetch(`/api/auth/signin`, {
    method: "POST",
    body: JSON.stringify(req),
  });
  await throwOnError(resp);
  return (await resp.json()) as SignInResponse;
};

export type SignUpRequest = {
  userName: string;
  password: string;
  email: string;
};

export type SignUpResponse = AuthSuccessResponse;

export const apiSignUp = async (
  req: SignUpRequest
): Promise<SignUpResponse> => {
  let resp = await fetch(`/api/auth/signup`, {
    method: "POST",
    body: JSON.stringify(req),
  });
  await throwOnError(resp);
  return (await resp.json()) as SignUpResponse;
};

export type RefreshRequest = {
  session: string;
};

export type RefreshTokenResponse = AuthSuccessResponse;

export const apiRefresh = async (
  req: RefreshRequest
): Promise<RefreshTokenResponse> => {
  let resp = await fetch(`/api/auth/refresh`, {
    method: "POST",
    body: JSON.stringify(req),
  });
  await throwOnError(resp);
  return (await resp.json()) as RefreshTokenResponse;
};
