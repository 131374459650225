import { useEffect } from "react";
import ToolBar from "../Components/ToolBar";
import { RootState } from "../State/RootReducer";
import { fetchTable } from "../State/TablesSlice";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../State/Hooks";
import { Alert } from "react-bootstrap";

const TablePage = () => {
  const { id } = useParams() as { id: string };
  const dispatch = useAppDispatch();
  const { table, error } = useAppSelector((state: RootState) => state.tables);

  useEffect(() => {
    if (!error) {
      dispatch(fetchTable(id));
    }
  }, [dispatch, error, id]);

  return (
    <>
      <ToolBar title={table?.displayName} />
      {!!error && <Alert variant="error">{error}</Alert>}
    </>
  );
};

export default TablePage;
