import * as auth from "../Auth/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import { SignInControl } from "../Auth/SignInControl";
import { useAppDispatch } from "../State/Hooks";

export const SignInPage = () => {
    const navigation = useNavigate();
    const loc = useLocation();
    const { from } = (loc.state as any) || { from: { pathname: "/" } };
    const dispatch = useAppDispatch();

    const onClickSignIn = async (userName: string, password: string): Promise<boolean> => {
        try {
            await auth.signInWithPassword(dispatch, userName, password);
        } catch {
            return false;
        }
        navigation(from);
        return true;
    };

    return (<SignInControl onSignIn={onClickSignIn} />);
};
