import pako from "pako";
import { throwOnError } from "./Errors";

export type UploadSessionResponse = {
  uploadUrl: string;
  filename: string;
};

export type UploadFileResponse = {
  sourceFileName: string;
  storedFileName: string;
  fileSize: number;
  contentType: string;
};

export const apiUploadFile = async (
  token: string | null,
  file: File | null | undefined,
  setProgress: (s: number) => void
): Promise<UploadFileResponse> => {
  if (!token) {
    throw new Error("No authorization session.");
  }
  if (!file) {
    throw new Error("No file selected.");
  }
  const fileSize = file.size;
  const uploadSession = await apiCreateUploadSession(
    token,
    file.type,
    fileSize
  );

  let start = 0;
  const chunkSize = 256 * 1024 * 8; // Google wants a multiple of 256KB, except the last chunk
  setProgress(0);

  let lastTask = Promise.resolve();
  do {
    const end = Math.min(start + chunkSize, fileSize);
    const chunk = file.slice(start, end);
    const newTask = gcpUploadFileChunk(
      uploadSession.uploadUrl,
      chunk,
      start,
      end,
      fileSize,
      lastTask,
      setProgress
    );
    start = end;

    await lastTask; // await the previous task in process so we don't stack up the scheduler with tasks.
    lastTask = newTask;
  } while (start < fileSize);
  await lastTask;

  setProgress(100);

  return {
    sourceFileName: file.name,
    storedFileName: uploadSession.filename,
    fileSize: fileSize,
    contentType: file.type,
  };
};

export const apiCreateUploadSession = async (
  token: string,
  contentType: string,
  size: number
): Promise<UploadSessionResponse> => {
  let resp = await fetch(`/api/upload`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "X-Upload-Content-Type": contentType,
      "X-Upload-Origin": `${window.location.protocol}//${window.location.host}`,
    },
  });
  await throwOnError(resp);
  return (await resp.json()) as UploadSessionResponse;
};

export const gcpUploadFileChunk = async (
  uploadUrl: string,
  chunk: Blob,
  start: number,
  end: number,
  size: number,
  waitFor: Promise<void>,
  setProgress: (pct: number) => void
): Promise<void> => {
  // go ahead and start compressing the next payload...
  const bytes = new Uint8Array(await chunk.arrayBuffer());
  const compressed = pako.gzip(bytes);
  // ... but wait for the previous upload to complete so they stay in order
  await waitFor;
  // send the next payload
  await fetch(uploadUrl, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Range": `bytes ${start}-${end - 1}/${end === size ? size : "*"}`,
      "Content-Encoding": "gzip",
    },
    body: compressed,
  });
  setProgress((100 * start) / size);
};
