import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { tryExtendSession } from "../Auth/Auth";
import { AppDispatch } from "./Hooks";
import rootReducer, { RootState } from "./RootReducer";

export const createStore = () => configureStore({
  reducer: rootReducer,
});

export const startSession = async (dispatch: AppDispatch): Promise<void> => {
  await tryExtendSession(dispatch);
  setInterval(async () => {
    await tryExtendSession(dispatch);
  }, 45 * 60 * 1000);
};

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
