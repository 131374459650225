import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTable,
  faUser,
  faXmark,
  faIdBadge,
} from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { signOut } from "../Auth/Auth";
import { useAppDispatch, useAppSelector } from "../State/Hooks";
import { ZipTableIcon } from "../Assets/Icons";
import { Dropdown, Form, Navbar, NavLink } from "react-bootstrap";
import { Sp1, Sp2 } from "./Spacer";
import Avatar from "@mui/joy/Avatar";

const ToolBar = ({ title }: { title?: string }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clickNewTable = () => {
    navigate("/new-table");
  };
  const clickSignOut = () => {
    signOut(dispatch);
    navigate("/auth/signin");
  };

  const { userName, displayName, email, photoUrl } = useAppSelector(
    (state) => state.user
  );

  return (<Navbar bg="light" className="px-3" expand="sm">
    <Navbar.Brand href="/"><ZipTableIcon width="1.5em" height="1.5em" /><Sp2 />{title ?? "ZipTable"}</Navbar.Brand>
    <Navbar.Toggle aria-controls="navbar" />
    <Navbar.Collapse id="navbar" className="gap-4 justify-content-end">
      <Form className="d-flex">
        <Form.Control type="search" placeholder="Search" />
      </Form>
      <Dropdown>
        <Dropdown.Toggle as={NavLink}><FontAwesomeIcon icon={faPlus} /><Sp1 /></Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
          <Dropdown.Item onClick={clickNewTable}><FontAwesomeIcon icon={faTable} /><Sp2 />New Table...</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle as={NavLink}>
          <div className="d-inline-block align-middle">
            <Avatar
              alt={displayName || email || ""}
              src={photoUrl || undefined}
            /></div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
          <Dropdown.Item><FontAwesomeIcon icon={faIdBadge} /><Sp2 />{displayName || userName || email || '<user>'}</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item><FontAwesomeIcon icon={faUser} /><Sp2 />Account</Dropdown.Item>
          <Dropdown.Item onClick={clickSignOut}><FontAwesomeIcon icon={faXmark} /><Sp2 />Sign Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Navbar.Collapse>
  </Navbar >);
};

export default ToolBar;
