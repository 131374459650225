import {
  BrowserRouter as Router,
  Route,
  Routes,
  RouteProps,
  Navigate,
  useLocation,
} from "react-router-dom";
import HomePage from "../Pages/HomePage";
import { SignInPage } from "../Pages/SignInPage";
import { SignUpPage } from "../Pages/SignUpPage";
import NewTablePage from "../Pages/NewTablePage";
import TablePage from "../Pages/TablePage";
import { useAppSelector } from "../State/Hooks";
import 'bootstrap/dist/css/bootstrap.css';
import NotFoundPage from "../Pages/Errors/NotFoundPage";

const Id = ({ children }: RouteProps): JSX.Element => {
  const { idToken, sessionQueried } = useAppSelector((state) => state.user);
  const { pathname } = useLocation();

  if (!sessionQueried) {
    return <></>;
  } else if (!!idToken) {
    return <>{children}</>;
  } else {
    return <Navigate to="/auth/signin" state={{ from: pathname }} />;
  }
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/auth/signin" element={<SignInPage />} />
        <Route path="/auth/signup" element={<SignUpPage />} />
        <Route
          path="/new-table"
          element={
            <Id>
              <NewTablePage />
            </Id>
          }
        />
        <Route
          path="/t/:id"
          element={
            <Id>
              <TablePage />
            </Id>
          }
        />
        <Route
          path="/"
          element={
            <Id>
              <HomePage />
            </Id>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
