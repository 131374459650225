import { throwOnError } from "./Errors";

export type ApiTable = {
  id: string;
  displayName: string;
};

export type ImportSettings = {
  uploadFile: string;
  contentType: string;
};

export type CreateTableData = {
  displayName: string;
  importSettings: ImportSettings;
};

export const apiCreateTable = async (
  token: string,
  tableData: CreateTableData
): Promise<ApiTable> => {
  let resp = await fetch(`/api/tables`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(tableData),
  });
  await throwOnError(resp);
  return (await resp.json()) as ApiTable;
};

export type ListTablesResponse = {
  tables: ApiTable[];
};

export const apiListTables = async (
  token: string
): Promise<ListTablesResponse> => {
  let resp = await fetch(`/api/tables`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  await throwOnError(resp);
  return (await resp.json()) as ListTablesResponse;
};

export type GetTableResponse = {
  table: ApiTable;
};

export const apiGetTable = async (
  token: string,
  id: string
): Promise<GetTableResponse> => {
  let resp = await fetch(`/api/table/${id}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  await throwOnError(resp);
  return (await resp.json()) as GetTableResponse;
};
