import pako from "pako";
import { throwOnError } from "./Errors";

export type TableSchema = {
  columns: ColumnInfo[];
  sampleData: string[][];
};

export type ColumnInfo = {
  columnId: number;
  name: string;
  datatype: ColumnType;
};

export type TableSchemaResponse = {
  status: number;
  schema: TableSchema;
};

export type ColumnType = "bl" | "str" | "f64" | "i64" | "u64" | "ts"; // "u64" not normally used in the UI

export const ColumnTypeMap = new Map([
  ["bl", "Boolean"],
  ["str", "String"],
  ["f64", "Float"],
  ["i64", "Integer"],
  ["ts", "TimeStamp"],
]);

export const apiDetectFileSchema = async (
  token: string | null,
  contentType: string,
  file: File
): Promise<TableSchemaResponse> => {
  const sample = file.slice(0, 1024 * 1024);
  const bytes = new Uint8Array(await sample.arrayBuffer());
  const compressed = pako.gzip(bytes);
  let resp = await fetch(`/api/analyze`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": contentType,
      "Content-Encoding": "gzip",
    },
    body: compressed,
  });
  await throwOnError(resp);
  return (await resp.json()) as TableSchemaResponse;
};
