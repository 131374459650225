import ToolBar from "../Components/ToolBar";
import TablesList from '../Components/TablesList';

const HomePage = () => {
  return (
    <>
      <ToolBar />
      <TablesList />
    </>
  );
};

export default HomePage;
