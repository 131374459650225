import { ChangeEvent, useState } from "react";
import { ColumnType, ColumnTypeMap, TableSchema } from "../Api/Schema";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/pro-solid-svg-icons";
import { IntegerInput } from "../Components/IntegerInput";
import { Button } from "react-bootstrap";
import { Sp1 } from "./Spacer";
import { Form } from "react-bootstrap";
import React from "react";

export const SchemaConfig = (props: {
  schema: TableSchema;
  onUpdateSchema: (schema: TableSchema) => void;
}) => {
  const { schema, onUpdateSchema } = props;
  const [sampleRowIndex, setSampleRowIndex] = useState(0);
  const dataLength = schema.sampleData.length;
  const dataLengthDigits = Math.ceil(Math.log10(dataLength + 2));

  const onClickBackIndex = () => {
    const newIndex = Math.max(0, sampleRowIndex - 1);
    setSampleRowIndex(newIndex);
  };
  const onClickForwardIndex = () => {
    const newIndex = Math.min(dataLength - 1, sampleRowIndex + 1);
    setSampleRowIndex(newIndex);
  };
  const onClickFirstIndex = () => {
    setSampleRowIndex(0);
  };
  const onClickLastIndex = () => {
    setSampleRowIndex(dataLength - 1);
  };
  const changeSampleRowIndex = (newValue: number) => {
    const v = Math.min(Math.max(1, newValue), dataLength);
    setSampleRowIndex(v - 1);
  };

  const changeColumnName =
    (id: number) => (evt: ChangeEvent<HTMLInputElement>) => {
      const columns = schema.columns;
      const idx = columns.findIndex((c) => c.columnId === id);
      const newSchema: TableSchema = update(schema, {
        columns: { [idx]: { $merge: { name: evt.target.value } } },
      });
      onUpdateSchema(newSchema);
    };
  const changeColumnType =
    (id: number) => (evt: ChangeEvent<HTMLSelectElement>) => {
      const columns = schema.columns;
      const idx = columns.findIndex((c) => c.columnId === id);
      const newSchema: TableSchema = update(schema, {
        columns: { [idx]: { $merge: { datatype: evt.target.value as ColumnType } } },
      });
      onUpdateSchema(newSchema);
    };

  const headerStyles = {
    fontWeight: 500,
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <div className="d-grid"
      style={{
        gridTemplateColumns: `[col-name] 2fr 20px [data-type] 1fr 20px [sample-data] 3fr`,
        rowGap: '10px',
        width: '100%',
      }}>
      <div style={{
        gridColumn: 'col-name',
        justifyContent: 'left',
        ...headerStyles,
      }}>Column Name</div>
      <div style={{
        gridColumn: 'data-type',
        justifyContent: 'left',
        ...headerStyles,
      }}>Data Type</div>
      <div style={{
        gridColumn: 'sample-data',
        justifyContent: 'center',
        gap: '3px',
        ...headerStyles,
      }}>
        <Button onClick={onClickFirstIndex} variant="light"><FontAwesomeIcon icon={faAnglesLeft} size="xs" /></Button>
        <Button onClick={onClickBackIndex} variant="light"><FontAwesomeIcon icon={faAngleLeft} size="xs" /></Button>
        <IntegerInput
          digits={dataLengthDigits}
          value={sampleRowIndex + 1}
          onChange={changeSampleRowIndex}
        /><Sp1 />
        of {dataLength} sampled rows<Sp1 />
        <Button onClick={onClickForwardIndex} variant="light"><FontAwesomeIcon icon={faAngleRight} size="xs" /></Button>
        <Button onClick={onClickLastIndex} variant="light"><FontAwesomeIcon icon={faAnglesRight} size="xs" /></Button>
      </div>
      {schema.columns.map((c, i) => (
        <React.Fragment key={`col-${c.columnId}`}>
          <Form.Control type="input" style={{ gridColumn: 'col-name' }}
            value={c.name}
            onChange={changeColumnName(c.columnId)}
          />
          <Form.Select style={{ gridColumn: 'data-type' }}
            value={c.datatype}
            onChange={changeColumnType(c.columnId)}
          >
            {Array.from(ColumnTypeMap.entries()).map((e, i) => (
              <option value={e[0]} key={e[0]}>{e[1]}</option>
            ))}
          </Form.Select>
          <Form.Control type="input" style={{ gridColumn: 'sample-data' }}
            value={schema.sampleData[sampleRowIndex][i]}
            readOnly={true}
          />
        </React.Fragment>
      ))}
    </div>
  );
};
