import { ChangeEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

interface IntegerInputProps {
  digits: number;
  value: number;
  onChange?: (newValue: number) => void;
}

export const IntegerInput = (props: IntegerInputProps) => {
  const { onChange, value, digits } = props;
  const [editingValue, setEditingValue] = useState(null as string | null);

  const updateValue = (val: string | null) => {
    if (!onChange || !val) return;
    const v = parseInt(val);
    if (isNaN(v)) return;
    onChange(v);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => updateValue(editingValue), 1000);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [editingValue]);

  const onChangeEvt = (evt: ChangeEvent<HTMLInputElement>) => {
    if (editingValue !== null) {
      setEditingValue(evt.target.value);
    } else {
      updateValue(evt.target.value);
    }
  };

  const onFocus = () => setEditingValue((value as any).toString());

  const onBlur = () => {
    updateValue(editingValue);
    setEditingValue(null);
  };

  return (
    <Form.Control
      type="input"
      style={{
        textAlign: "right",
        fontWeight: 500, fontSize: "14px",
        width: Math.max(digits * 12 + 8, 40),
      }}
      value={editingValue ?? value.toString()}
      onChange={onChangeEvt}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};
