import { Form, FloatingLabel } from 'react-bootstrap';

type TextFieldProps = {
    id: string;
    label: string;
    placeholder?: string;
    type?: string;
    disabled?: boolean;
    readOnly?: boolean;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
};

export const TextField = (props: TextFieldProps) => {
    const { id, label, type, readOnly, disabled, placeholder, value, onChange, onKeyDown } = props;
    return (
        <Form.Group controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                type={type ?? "input"}
                color="primary"
                readOnly={readOnly}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown} />
        </Form.Group>
    );
};