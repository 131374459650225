import { faFaceAstonished } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Sp2 } from "../../Components/Spacer";
import ToolBar from "../../Components/ToolBar";

const NotFoundPage = () => {
    return (
        <>
            <ToolBar />
            <div>
                <h1 className="my-5 text-center"><FontAwesomeIcon icon={faFaceAstonished} /><Sp2 />Page not found!</h1>
            </div>
        </>
    );
};

export default NotFoundPage;
