import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiGetTable, apiListTables, ApiTable } from "../Api/Tables";
import { AppThunk } from "./Store";

interface TableState {
  tables: ApiTable[] | null;
  table: ApiTable | null;
  error: string | null;
}

let initialState: TableState = {
  tables: null,
  table: null,
  error: null,
};

const tablesSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    getTablesStart(state) {
      state.tables = null;
      state.error = null;
    },
    getTablesSuccess(state, action: PayloadAction<ApiTable[]>) {
      state.tables = action.payload;
      state.error = null;
    },
    getTablesFailure(state, action: PayloadAction<string>) {
      state.table = null;
      state.error = action.payload;
    },
    clearTables(state) {
      state.tables = null;
      state.error = null;
    },
    getTableStart(state) {
      state.table = null;
      state.error = null;
    },
    getTableSuccess(state, action: PayloadAction<ApiTable>) {
      state.table = action.payload;
      state.error = null;
    },
    getTableFailure(state, action: PayloadAction<string>) {
      state.table = null;
      state.error = action.payload;
    },
  },
});

export const {
  getTablesStart,
  getTablesSuccess,
  getTablesFailure,
  clearTables,
  getTableStart,
  getTableSuccess,
  getTableFailure,
} = tablesSlice.actions;

export default tablesSlice.reducer;

export const fetchTables = (): AppThunk => async (dispatch, getState) => {
  try {
    const idToken = getState().user.idToken ?? "";
    dispatch(getTablesStart());
    const tablesResp = await apiListTables(idToken);
    dispatch(getTablesSuccess(tablesResp.tables));
  } catch (err) {
    dispatch(getTablesFailure((err as Error).message));
  }
};

export const fetchTable =
  (id: string): AppThunk =>
    async (dispatch, getState) => {
      try {
        const idToken = getState().user.idToken ?? "";
        dispatch(getTableStart());
        const tableResp = await apiGetTable(idToken, id);
        dispatch(getTableSuccess(tableResp.table));
      } catch (err) {
        dispatch(getTableFailure((err as Error).message));
      }
    };
