import React from "react";

export const ZipTableIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg {...props} viewBox="0 0 488 488">
            <rect x="10" y="10" width="468" height="468" rx="38" style={{ fill: "#fff" }} />
            <path d="M452,32a28,28,0,0,1,28,28V452a28,28,0,0,1-28,28H60a28,28,0,0,1-28-28V60A28,28,0,0,1,60,32H452m0-20H60A48,48,0,0,0,12,60V452a48,48,0,0,0,48,48H452a48,48,0,0,0,48-48V60a48,48,0,0,0-48-48Z" transform="translate(-12 -12)" style={{ fill: "#2e3192" }} />
            <line y1="82" x2="488" y2="82" style={{ fill: 'none', stroke: '#2e3192', strokeMiterlimit: 10, strokeWidth: '20px' }} />
            <line y1="210" x2="488" y2="210" style={{ fill: 'none', stroke: '#2e3192', strokeMiterlimit: 10, strokeWidth: '20px' }} />
            <line y1="348" x2="488" y2="348" style={{ fill: 'none', stroke: '#2e3192', strokeMiterlimit: 10, strokeWidth: '20px' }} />
            <line x1="162.67" x2="162.67" y2="488" style={{ fill: 'none', stroke: '#2e3192', strokeMiterlimit: 10, strokeWidth: '20px' }} />
            <line x1="325.33" x2="325.33" y2="488" style={{ fill: 'none', stroke: '#2e3192', strokeMiterlimit: 10, strokeWidth: '20px' }} />
            <path d="M53,5H435a48,48,0,0,1,48,48V85a0,0,0,0,1,0,0H5a0,0,0,0,1,0,0V53A48,48,0,0,1,53,5Z" style={{ fill: '#2e3192' }} />
            <path d="M98,251c-.13-23.9,0-11.58,0-62,0-24,19-32,43-32l243,1c25.12,0,37.49,38,16,51-71.52,43.32-247.55,149.58-293,184-12.23,9.26-5.34,34,10,34,69.6.12,214,0,256,0,12.13,0,40.24-17.25,41-34,1-22,0-24.13,0-59" transform="translate(-12 -12)" style={{ fill: 'none', stroke: '#f7931e', strokeMiterlimit: 10, strokeWidth: "60px" }} />
        </svg>
    );
}