import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  idToken: string | null;
  sessionQueried: boolean;
  photoUrl: string | null;
  displayName: string | null;
  email: string | null;
  userName: string | null;
}

let initialState: UserState = {
  idToken: null,
  sessionQueried: false,
  displayName: "",
  email: "",
  photoUrl: null,
  userName: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserState>) {
      const {sessionQueried, displayName, email, userName, idToken, photoUrl} = action.payload;
      state.displayName = displayName;
      state.email = email;
      state.userName = userName;
      state.idToken = idToken;
      state.photoUrl = photoUrl;
      state.sessionQueried = sessionQueried;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
