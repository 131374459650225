import { faFileImport } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import FileUpload from './FileUpload';

const FileDragUploadPanel = (props: {
    onBeginUpload: (file: File) => void,
}) => {
    const { onBeginUpload } = props;
    const [dragging, setDragging] = useState(false);

    const onDragEnter = (evt: React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        evt.stopPropagation();
        setDragging(true);
    };

    const onDragLeave = (evt: React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        evt.stopPropagation();
        setDragging(false);
    };

    const onDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        evt.stopPropagation();
        evt.dataTransfer.dropEffect = "copy";
    };

    const onDrop = (evt: React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (!evt.dataTransfer) return;

        const files = [...evt.dataTransfer.files];
        onBeginUpload(files[0]);
    };

    const defaultStyle = {
        borderStyle: 'dashed',
    };

    const draggingStyle = {
        borderStyle: 'solid',
    };

    return (
        <div style={{
            position: 'relative',
            padding: 4,
            borderWidth: 4,
            borderRadius: 16,
            borderColor: 'rgba(0, 0, 0, 0.12)',
            height: 250,
            ...(dragging ? draggingStyle : defaultStyle),
        }} onDragEnter={onDragEnter}>
            <div style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center'
            }}>
                {!dragging
                    ? (<>
                        <div style={{ textAlign: 'center' }}>
                            Drag and drop a data file into this window<br />
                            or choose a file to upload.
                        </div>
                        <div>
                            <FileUpload onBeginUpload={onBeginUpload} />
                        </div>
                    </>)
                    : (<>
                        <div style={{ textAlign: 'center' }}>
                            <FontAwesomeIcon icon={faFileImport} size="5x" /><br /><br />
                            Drop file here to start uploading.
                        </div>
                    </>)}
            </div>
            {/* This is an overlay used to capture the drop zone*/}
            <div style={{
                position: 'absolute',
                backgroundColor: 'transparent',
                top: 0, left: 0, width: '100%', height: '100%',
                // send pointer events to the overlay while dragging but through otherwise
                pointerEvents: dragging ? 'auto' : 'none',
            }}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop} />
        </div>
    );
}

export default FileDragUploadPanel;
