import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import * as humanize from 'humanize-plus';

const FileUploader = (props: {
    onBeginUpload: (file: File) => void,
}) => {

    const fileInput = useRef<HTMLInputElement>(null);
    const [, setFileName] = useState("");

    const clickBrowse = () => fileInput.current?.click();

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = fileInput.current?.files?.item(0);
        if (!!file) {
            setFileName(`${file.name}  -  [${humanize.fileSize(file.size)}]`);
            props.onBeginUpload(file);
        }
    };

    return (
        <>
            <Button onClick={clickBrowse} color="primary">Choose Data File...</Button>
            <input type="file" ref={fileInput} style={{ display: 'none' }} onChange={onFileChange} />
        </>
    );
}

export default FileUploader;
