import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App/App";
import { createStore, startSession } from "./State/Store";

(() => {
  const store = createStore();

  startSession(store.dispatch); // not awaited to allow session creation concurrently with initial render

  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
})();
