import * as auth from "../Auth/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import { SignUpControl } from "../Auth/SignUpControl";
import { useAppDispatch } from "../State/Hooks";

export const SignUpPage = () => {
    const navigation = useNavigate();
    const loc = useLocation();
    const { from } = (loc.state as any) || { from: { pathname: "/" } };
    const dispatch = useAppDispatch();

    const onClickSignUp = async (email: string, password: string, userName: string): Promise<string> => {
        try {
            await auth.signUpWithUsernameAndPassword(dispatch, userName, password, email);
        } catch (err) {
            return (err as Error).message;
        }
        navigation(from);
        return "";
    };

    return (<SignUpControl onSignUp={onClickSignUp} />);
};
