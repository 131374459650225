export const Panel = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { children, style, className } = props;
    return (<div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        border: "1px solid #dee2e6",
        borderRadius: "10px",
        gap: "10px",
        padding: "10px",
        ...style,
    }} className={className}>
        {children}
    </div>);
};