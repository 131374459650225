import { setUser } from "../State/UserSlice";
import { apiRefresh, apiSignIn, apiSignUp, UserData } from "../Api/Auth";
import { resetStore } from "../State/RootReducer";
import { AppDispatch } from "../State/Hooks";

export const signOut = (dispatch: AppDispatch) => {
  window.localStorage.clear();
  dispatch(resetStore());
};

export const updateUser = async (
  dispatch: AppDispatch,
  token: string | null,
  user: UserData | null
): Promise<void> => {
  dispatch(
    setUser({
      idToken: token,
      sessionQueried: true,
      userName: user?.userName ?? "",
      email: user?.email ?? "",
      displayName: user?.displayName ?? "",
      photoUrl: user?.picture ?? "",
    })
  );
};

export const saveSession = (session: string) => {
  window.localStorage.setItem("_session", session);
};

export const tryExtendSession = async (dispatch: AppDispatch): Promise<void> => {
  const sessionId = window.localStorage.getItem("_session");
  if (!sessionId) {
    await updateUser(dispatch, null, null);
    return;
  }
  try {
    const resp = await apiRefresh({ session: sessionId });
    await updateUser(dispatch, resp.token, resp.user);
    saveSession(resp.session);
  } catch (e) {
    window.localStorage.clear();
    await updateUser(dispatch, null, null);
  }
};

export const signInWithPassword = async (
  dispatch: AppDispatch,
  userName: string,
  password: string
): Promise<void> => {
  const resp = await apiSignIn({ userName, password });
  await updateUser(dispatch, resp.token, resp.user);
  saveSession(resp.session);
};

export const signUpWithUsernameAndPassword = async (
  dispatch: AppDispatch,
  userName: string,
  password: string,
  email: string
): Promise<void> => {
  const resp = await apiSignUp({ userName, password, email });
  await updateUser(dispatch, resp.token, resp.user);
  saveSession(resp.session);
};
