import CircularProgress from '@mui/joy/CircularProgress';
import { CssVarsProvider } from '@mui/joy/styles';

const FileProgress = (props: {
    caption: string,
    detail: string,
    progress?: number,
    indeterminate?: boolean
}) => {
    const { caption, detail, progress, indeterminate } = props;
    return (
        <CssVarsProvider>
            <div className="border border-1 rounded-2 p-3 d-flex flex-row" >
                <div className="d-flex flex-column flex-grow-1">
                    <div style={{ fontSize: '1.25rem', fontWeight: 600 }}>{caption}</div>
                    <div style={{ fontSize: '0.75rem' }}>{detail}</div>
                </div>
                <div className="align-self-center">
                    {indeterminate
                        ? (progress !== 100 && <CircularProgress size="md" color="primary" />)
                        : (progress !== 100 && <div className="d-inline-flex position-relative">
                            <CircularProgress size="md" color="primary" determinate value={progress}>
                                {`${Math.round(progress ?? 0)}%`}
                            </CircularProgress>
                        </div>)
                    }
                </div>

            </div>
        </CssVarsProvider>
    );
}

export default FileProgress;
