import { useEffect } from "react";
import { fetchTables } from "../State/TablesSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faTableTree } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../State/Hooks";
import { Sp2 } from "./Spacer";
import { Panel } from "../Assets/Panel";
import { Alert } from "react-bootstrap";

const TablesList = () => {
  const dispatch = useAppDispatch();
  const { tables, error } = useAppSelector((state) => state.tables);

  useEffect(() => {
    if (!tables && !error) {
      dispatch(fetchTables());
    }
  }, [dispatch, tables, error]);

  return (
    <div className="mx-auto" style={{ maxWidth: '500px' }}>
      <h4 className="mt-4"><FontAwesomeIcon icon={faTableTree} /><Sp2 />Tables</h4>
      {!!error && <Alert variant="danger">{error.toString()}</Alert>}
      <Panel className="mt-3">
        {!!tables &&
          tables.map((t) => (
            <div key={t.id}>
              <FontAwesomeIcon icon={faTable} /><Sp2 /><Link to={"/t/" + t.id}>{t.displayName}</Link>
            </div>
          ))}
      </Panel>
    </div>
  );
};

export default TablesList;
